import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/app/frontend/home-website/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__home" */ '../home'),
        })
      : require('../home').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
  {
    path: '/values/enterprise',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__worth" */ '../worth'),
        })
      : require('../worth').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
  {
    path: '/about/us',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__about" */ '../about'),
        })
      : require('../about').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
  {
    path: '/power',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__power" */ '../power'),
        })
      : require('../power').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
  {
    path: '/brand',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__brand" */ '../brand'),
        })
      : require('../brand').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__404__index" */ '../404/index'),
        })
      : require('../404/index').default,
    exact: true,
    _title: 'deepreco-home',
    _title_default: 'deepreco-home',
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
